import { PublicClientApplication } from '@azure/msal-browser';
import {
	AuthenticatedTemplate,
	MsalProvider,
	UnauthenticatedTemplate
} from '@azure/msal-react';
import {Providers} from '@microsoft/mgt-element';
import {Msal2Provider, Msal2PublicClientApplicationConfig} from '@microsoft/mgt-msal2-provider';

import { createMongoAbility } from '@casl/ability';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';
import { FeatureFlagsProvider } from './components/hooks/FeatureFlagContext';

import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { MyApp } from './App';
import { AbilityContext } from './components/AbilityContext';
import { SignIn } from './pages/SignIn/SignIn';

import { msalConfig } from './utils/authConfig';

const instance: PublicClientApplication = new PublicClientApplication( msalConfig );

declare global {
	interface Window {
		env: any;
	}
}
const providerConfig: Msal2PublicClientApplicationConfig = {
	publicClientApplication: instance,
};

Providers.globalProvider = new Msal2Provider( providerConfig );

LicenseInfo.setLicenseKey( window.env.muiLicenseKey );

type Actions = 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Property' | 'Project';

const container = document.getElementById( 'root' );
const root = createRoot( container! ); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<MsalProvider instance={ instance }>
			<AuthenticatedTemplate>
				<LocalizationProvider dateAdapter={ AdapterDayjs }>
					<AbilityContext.Provider value={ createMongoAbility<[ Actions, Subjects ]>() }>
						<FeatureFlagsProvider>
							<ConfirmProvider>
								<MyApp/>
							</ConfirmProvider>
						</FeatureFlagsProvider>
					</AbilityContext.Provider>
				</LocalizationProvider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<SignIn/>
			</UnauthenticatedTemplate>
		</MsalProvider>
	</React.StrictMode>
);

