import { InteractionStatus } from '@azure/msal-browser';
import { IMsalContext } from '@azure/msal-react';
import { AxiosInstance } from 'axios';
import { getTokenRequest, } from 'utils/authConfig';
import { getAuthenticatedApiClient } from './api-client';
import {
	ApiCore,
	ApiCoreOptions
} from './api-core';

export const reAuth = async ( msalContext: IMsalContext, scopes?:string[] ) => {
	const { instance, accounts } = msalContext;
	return async () => {
		let results = await instance.acquireTokenSilent( getTokenRequest( accounts, scopes ) );
		return results.accessToken;
	};
};

export const getApi = async ( msalContext: IMsalContext,
                              options: ApiCoreOptions ): Promise<ApiCore> => {
	const { instance, accounts, inProgress } = msalContext;

	return instance.acquireTokenSilent( getTokenRequest( accounts ) )
	               .then( result => result.accessToken )
	               .then( ( token: string ) => getAuthenticatedApiClient( token ) )
	               .then( ( client: AxiosInstance ) => new ApiCore( options, client ) )
	               .catch( ( error ) => {
		               if ( accounts && inProgress === InteractionStatus.None ) {
			               return instance
				               .acquireTokenPopup( getTokenRequest( accounts ) )
				               .then( ( response ) => {
					               return response;
				               } )
				               .then( result => result.accessToken )
				               .then( ( token: string ) => getAuthenticatedApiClient( token ) )
				               .then( ( client: AxiosInstance ) => new ApiCore( options, client ) );
		               }
		               throw error;
	               } );


};
