import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import { Business, Work } from '@mui/icons-material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, debounce, InputBase, ListItem, ListSubheader } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AbilityContext } from './AbilityContext';
import { getApiProvider } from './hooks/API';

const Search = styled('div')(({theme}) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		width: 'auto',
	},
}));
const SearchIconWrapper = styled('div')(({theme}) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({theme}) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${ theme.spacing(4) })`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '0ch',
			'&:focus': {
				width: '30ch',
			},
		},
	},
}));

const SearchTypes = {
	Property: {path: (value) => `/communities/${ value.id }`, icon: <ApartmentIcon/>},
	Project: {path: (value) => `/projects/${ value.code }/${ value.subCode ?? 1 }`, icon: <Work/>},
	Partner: {path: (value) => `/partners/${ value.id }`, icon: <Business/>},
};

export default function GlobalSearch({url = '/search', empty = [], limit = 5, minInput = 3}) {
	const msalContext = useMsal();

	const ability = useContext(AbilityContext);
	const intl = useIntl();
	const [options, setOptions] = useState(empty);
	const navigate = useNavigate();
	const [searchContext, setSearchContext] = useState([]);
	const [noOptionsText, setNoOptionsText] = useState('Please enter search text');
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		let newContext = ['Property', 'Project'];
		const partnerPageView = ability.can('Partner','read');
		if (partnerPageView) {
			console.debug('Partner search allowed');
			newContext = [...newContext, 'Partner'];
		}
		setSearchContext(newContext);
	}, [ability]);

	const _find = async (query) => {
		let data = empty;
		console.dir(searchContext);
		setNoOptionsText(query ? query.length < minInput ? 'Keep Typing...' : 'Not Found' : 'Please enter search text');
		if (query && query.length >= minInput) {
			setLoading(true);
			const config = {
				params: {query, limit, types: searchContext},
			};
			const api = await getApiProvider( msalContext )
			const result = await api.getAll(`${ url }`, config);
			data = result?.data ?? empty;
		}
		setLoading(false);
		await setOptions(data);
	};
	const debouncedFind = useMemo(() => debounce(_find, 300), []);
	const handleChange = async (event, newValue, reason) => {
		if (!reason) {
			return;
		}
		const to = SearchTypes[newValue.type].path(newValue);
		navigate(to);
	};
	return (
		<Search>
			<SearchIconWrapper>
				<SearchIcon/>
			</SearchIconWrapper>
			<Autocomplete
				disableClearable
				options={ options }
				noOptionsText={ noOptionsText }
				loading={ loading }
				filterOptions={ o => o }
				groupBy={ o => intl.formatMessage({id: o.type.toLowerCase(), defaultMessage: o.type}) }
				getOptionLabel={ (option) => {
					return option?.name ?? option ?? '';
				} }
				onChange={ handleChange }
				onInputChange={ (event, value, reason) => {
					if (reason === 'input') {
						debouncedFind(value);
					}
					else {
						setOptions(empty);
					}
				} }
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				renderGroup={ (params) => <ListSubheader>
					{ params.children.length >= limit ?
						`${ params.group } (First ${ limit } results)` : params.group }
					{ params.children }
				</ListSubheader> }
				renderOption={ (props, option) => {
					return (
						<ListItem { ...props }>
							<Grid container alignItems="center">
								<Grid item sx={ {display: 'flex', width: 44} }>
									{ SearchTypes[option.type].icon }
								</Grid>
								<Grid item sx={ {width: 'calc(100% - 44px)', wordWrap: 'break-word'} }>
									{ option.name }
								</Grid>
							</Grid>
						</ListItem>
					);
				} }

				renderInput={ (params) => {
					return <StyledInputBase
						{ ...params }
						{ ...params.InputProps }
						type="search"
						placeholder="Global Search"
					/>;
				} }
			/>
		</Search>
	);
}
